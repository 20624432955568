import React from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import { Helmet } from "react-helmet"
import { Carousel, Container, Col, Row } from "react-bootstrap"
import { StaticImage } from "gatsby-plugin-image"
import JotformEmbed from "react-jotform-embed"
import Layout from "../components/layout"
import SunLogo from "../images/sunxtreme-favicon.png"
import "../styles/general-styles.css"

export default function Home() {
  return (
    <div className="page-background">
      <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
          <meta
            name="description"
            content="Sunxtreme is a new type of solar pool heating system that is tough, durable, and designed to last."
          />
          <meta
            name="keywords"
            content="solar pool, solar pool heating, solar pool system, solar pool heating system, solar panels for pools, residential pool heating, residential solar pool heating, commercial pool heating, commercial solar pool heating, commercial solar pool panels, warm pool, pool heating"
          />
          <meta name="author" content="Sean Rooney" />
          <link rel="icon" href={SunLogo} type="image/gif" sizes="16x16" />
          <link rel="canonical" href="https://sunxtreme.com" />
          <title>SunXtreme- Solar Pool Heating</title>
        </Helmet>
        <Container fluid="xxl" className="container-background">
          <div className="page-header-background">
            <h1 className="page-header">EXTEND YOUR SWIM SEASON</h1>
          </div>
          <br />
          <h2 className="page-brand-statement">Spend more time in the pool!</h2>

          {/* DESKTOP VERSION OF TOP SECTION */}

          <div className="desktop-component">
            <Row>
              <Col>
                <p className="article-text">
                  SunXtreme is a new and more robust solar pool heating system
                  designed for durability and longevity. SunXtreme is a brand of
                  Green Energy Solar- a leading provider of solar pool heating
                  systems for over 30 years.
                </p>
                <p className="article-text">
                  Don't waste money on another type of pool heating system. The
                  cost for gas and electricity will always increase, but the
                  Sun's energy will be free forver! Heat your pool responsibly
                  and sustainably with Sunxtreme Solar Pool Heating.
                </p>
                <h2 className="page-sub-header">DESIGNED FOR LONGEVITY</h2>
                <p className="article-text">
                  The Sunxtreme system is easily the toughest solar pool heating
                  system made anywhere in the world and is sized to suit a wide
                  variety of installation needs. Every component and part in the
                  system has been redesigned for greater strength and
                  durability. New methods and technologies have been developed
                  to eliminate any potential point of weakness in the system.
                </p>
                <Carousel>
                  <Carousel.Item>
                    <StaticImage
                      src="../images/gallery-images/gallery-one.jpg"
                      alt="Sunxtreme system on roof of house"
                      className="carousel-images"
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <StaticImage
                      src="../images/gallery-images/gallery-two.jpg"
                      alt="Sunxtreme commercial system on roof"
                      className="carousel-images"
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <StaticImage
                      src="../images/gallery-images/gallery-three.jpg"
                      alt="Sunxtreme system on roof"
                      className="carousel-images"
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <StaticImage
                      src="../images/gallery-images/gallery-four.jpg"
                      alt="Sunxtreme system"
                      className="carousel-images"
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <StaticImage
                      src="../images/gallery-images/gallery-five.jpg"
                      alt="Sunxtreme system on roof of house"
                      className="carousel-images"
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <StaticImage
                      src="../images/gallery-images/gallery-six.jpg"
                      alt="Sunxtreme system on roof of house"
                      className="carousel-images"
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <StaticImage
                      src="../images/gallery-images/gallery-seven.jpg"
                      alt="Sunxtreme commercial system on roof"
                      className="carousel-images"
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <StaticImage
                      src="../images/gallery-images/gallery-eight.jpg"
                      alt="Sunxtreme system on roof"
                      className="carousel-images"
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <StaticImage
                      src="../images/gallery-images/gallery-nine.jpg"
                      alt="Sunxtreme commercial system on roof"
                      className="carousel-images"
                    />
                  </Carousel.Item>
                </Carousel>
              </Col>
              <Col>
                <StaticImage
                  src="../images/sph-gold-seal.png"
                  alt="over 40,000 installations gold seal"
                />
                <StaticImage
                  src="../images/solar-installers-graphic.png"
                  alt="Solar installers on ladder with panels"
                />
              </Col>
            </Row>
          </div>

          {/* MOBILE VERSION OF TOP SECTION */}

          <div className="mobile-component">
            <p className="article-text">
              SunXtreme is a new and more robust solar pool heating system
              designed for durability and longevity. SunXtreme is a brand of
              Green Energy Solar- a leading provider of solar pool heating
              systems for over 30 years.
            </p>
            <StaticImage
              src="../images/sph-gold-seal.png"
              alt="over 40,000 installations gold seal"
            />
            <Carousel>
              <Carousel.Item>
                <StaticImage
                  src="../images/gallery-images/gallery-one.jpg"
                  alt="Sunxtreme system on roof of house"
                />
              </Carousel.Item>
              <Carousel.Item>
                <StaticImage
                  src="../images/gallery-images/gallery-two.jpg"
                  alt="Sunxtreme commercial system on roof"
                />
              </Carousel.Item>
              <Carousel.Item>
                <StaticImage
                  src="../images/gallery-images/gallery-three.jpg"
                  alt="Sunxtreme system on roof"
                />
              </Carousel.Item>
              <Carousel.Item>
                <StaticImage
                  src="../images/gallery-images/gallery-four.jpg"
                  alt="Sunxtreme system"
                />
              </Carousel.Item>
              <Carousel.Item>
                <StaticImage
                  src="../images/gallery-images/gallery-five.jpg"
                  alt="Sunxtreme system on roof of house"
                />
              </Carousel.Item>
              <Carousel.Item>
                <StaticImage
                  src="../images/gallery-images/gallery-six.jpg"
                  alt="Sunxtreme system on roof of house"
                />
              </Carousel.Item>
              <Carousel.Item>
                <StaticImage
                  src="../images/gallery-images/gallery-seven.jpg"
                  alt="Sunxtreme commercial system on roof"
                />
              </Carousel.Item>
              <Carousel.Item>
                <StaticImage
                  src="../images/gallery-images/gallery-eight.jpg"
                  alt="Sunxtreme system on roof"
                />
              </Carousel.Item>
              <Carousel.Item>
                <StaticImage
                  src="../images/gallery-images/gallery-nine.jpg"
                  alt="Sunxtreme commercial system on roof"
                />
              </Carousel.Item>
            </Carousel>
            <p className="article-text">
              Don't waste money on another type of pool heating system. The cost
              for gas and electricity will always increase, but the Sun's energy
              will be free forver! Heat your pool responsibly and sustainably
              with Sunxtreme Solar Pool Heating.
            </p>
            <StaticImage
              src="../images/solar-installers-graphic.png"
              alt="Solar installers on ladder with panels"
            />
            <h2 className="page-sub-header">DESIGNED FOR LONGEVITY</h2>
            <p className="article-text">
              The Sunxtreme system is easily the toughest solar pool heating
              system made anywhere in the world and is sized to suit a wide
              variety of installation needs. Every component and part in the
              system has been redesigned for greater strength and durability.
              New methods and technologies have been developed to eliminate any
              potential point of weakness in the system.
            </p>
          </div>
          <br />
          <div className="page-header-alt-background">
            <h2 className="page-header">IMPROVED DESIGN</h2>
          </div>
          <br />
          <h3 className="page-brand-statement">
            Sturdy, durable, and efficient
          </h3>

          {/* DESKTOP VERSION OF MIDDLE SECTION */}

          <div className="desktop-component">
            <Row>
              <Col>
                <StaticImage
                  src="../images/sunxtreme-tubes.png"
                  className=" sunxtreme-parts-image"
                  alt="SunXtreme solar panel tubes"
                />
                <p className="article-text">
                  The super tough and durable tube connections are totally
                  encased and shielded, making them many times more secure than
                  an insert. Each fully assembled panel is pressure tested and
                  performs past the limits of any other panel. No solar pool
                  heating tubes are thicker, more durable, or stronger than
                  those designed for SunXtreme.
                </p>
              </Col>
              <Col>
                <StaticImage
                  src="../images/sunxtreme-pipe-junction.png"
                  className=" sunxtreme-parts-image"
                  alt="SunXtreme solar panel pipe junction"
                />
                <p className="article-text">
                  Each junction of the pipework connections are cam-locked and
                  have three individual water seals to ensure there is no chance
                  of leakage. When using the tool provided, it takes just
                  one-eighth of a turn to tighten and lock to a perfect seal in
                  place every time.
                </p>
              </Col>
              <Col>
                <StaticImage
                  src="../images/sunxtreme-roof-clamp.png"
                  className=" sunxtreme-parts-image"
                  alt="SunXtreme solar panel roof clamp"
                />
                <p className="article-text">
                  The roof clamps reduce the number of penetrations to the roof
                  tile. The top clamp holds the panel firmly and securely in
                  place while it expands as it heats and contracts as it cools.
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <br />
                <h2 className="page-sub-header">HOW DOES IT WORK?</h2>
                <p className="article-text">
                  <strong>Naturally, from the Sun.</strong> SunXtreme’s solar
                  panels are installed on your roof or another sunny location.
                  Your pool water is pumped through hundreds of tubes in the
                  collectors where it absorbs the sun’s heat (think of a garden
                  hose lying in the sun). This warmed water is then returned to
                  the pool for your enjoyment. Our solar controller will
                  regulate the temperature to avoid overheating, bringing your
                  pool water to your desired temperature. Double your swim
                  season today!
                </p>
              </Col>
              <Col>
                <StaticImage
                  src="../images/sph-layout-graphic.png"
                  alt="Sunxtreme solar pool heating layout"
                  className="layout-graphic"
                />
              </Col>
            </Row>
          </div>

          {/* MOBILE VERSION OF MIDDLE SECTION */}

          <div className="mobile-component">
            <StaticImage
              src="../images/sunxtreme-tubes.png"
              className=" sunxtreme-parts-image"
              alt="SunXtreme solar panel tubes"
            />
            <p className="article-text">
              The super tough and durable tube connections are totally encased
              and shielded, making them many times more secure than an insert.
              Each fully assembled panel is pressure tested and performs past
              the limits of any other panel. No solar pool heating tubes are
              thicker, more durable, or stronger than those designed for
              SunXtreme.
            </p>
            <StaticImage
              src="../images/sunxtreme-pipe-junction.png"
              className=" sunxtreme-parts-image"
              alt="SunXtreme solar panel pipe junction"
            />
            <p className="article-text">
              Each junction of the pipework connections are cam-locked and have
              three individual water seals to ensure there is no chance of
              leakage. When using the tool provided, it takes just one-eighth of
              a turn to tighten and lock to a perfect seal in place every time.
            </p>
            <StaticImage
              src="../images/sunxtreme-roof-clamp.png"
              className=" sunxtreme-parts-image"
              alt="SunXtreme solar panel roof clamp"
            />
            <p className="article-text">
              The roof clamps reduce the number of penetrations to the roof
              tile. The top clamp holds the panel firmly and securely in place
              while it expands as it heats and contracts as it cools.
            </p>
            <br />
            <h2 className="page-sub-header">HOW DOES IT WORK?</h2>
            <p className="article-text">
              <strong>Naturally, from the Sun.</strong> SunXtreme’s solar panels
              are installed on your roof or another sunny location.
            </p>
            <StaticImage
              src="../images/sph-layout-graphic.png"
              alt="Sunxtreme solar pool heating layout"
              className="layout-graphic"
            />
            <p className="article-text">
              Your pool water is pumped through hundreds of tubes in the
              collectors where it absorbs the sun’s heat (think of a garden hose
              lying in the sun). This warmed water is then returned to the pool
              for your enjoyment. Our solar controller will regulate the
              temperature to avoid overheating, bringing your pool water to your
              desired temperature. Double your swim season today!
            </p>
            <div>
              <StaticImage
                src="../images/site-stamps-mobile.png"
                alt="various logos and seals and flags- SRCC Certified, BBB A+, Use Solar Seal, Pet Friendly Seal, 5 Star Company, Best Product Guarantee, Angi logo, USA Flag, Canada Flag, Australian Flag"
              />
            </div>
            <br />
          </div>

          {/* DESKTOP VERSION OF BOTTOM SECTION */}

          <div className="desktop-component">
            <Row>
              <Col>
                <StaticImage
                  src="../images/couple-in-pool.png"
                  alt="couple in pool"
                />
              </Col>
              <Col>
                <StaticImage
                  src="../images/dog-in-pool.png"
                  alt="dog in pool"
                />
              </Col>
              <Col>
                <StaticImage
                  src="../images/mom-with-daughter-in-pool.png"
                  alt="mom with daughter in pool"
                />
              </Col>
            </Row>
            <br />
            <h2 className="page-brand-statement">Get a free estimate</h2>
            <p className="article-text">
              Your pool is an important and valuable part of your home. Why not
              make sure you can use it whenever you want- even through colder
              weather? With a SunXtreme solar pool heating system, you can swim
              comfortably longer through the year! Contact us to get a free
              quote on our solar pool heating products for your home or
              business. <br />
              Start saving today by harnessing the sun's energy!
            </p>
          </div>

          {/* MOBILE VERSION OF BOTTOM SECTION */}

          <div className="mobile-component">
            <div>
              <StaticImage
                src="../images/couple-in-pool.png"
                alt="couple in pool"
              />
            </div>
            <br />
            <h2 className="page-brand-statement">Get a free estimate</h2>
            <p className="article-text">
              Your pool is an important and valuable part of your home. Why not
              make sure you can use it whenever you want- even through colder
              weather? Start saving today by harnessing the sun's energy!
            </p>
          </div>
          <div className="jot-form-box" id="free-estimate">
            <JotformEmbed
              className="jot-form"
              src="https://form.jotform.com/202934716192053"
            />
          </div>
          <br />
        </Container>
      </Layout>
    </div>
  )
}
